import classNames from 'classnames';

export interface ProjectTitleProps {
  className?: string;
  companyName?: string;
  roleType?: string;
  title: string;
}

/**
 * @name ProjectTitle
 * @description Renders a pretty project title based off of the companyName and roleType
 */
export const ProjectTitle = (props: ProjectTitleProps) => {
  const { className, companyName, roleType, title } = props;

  // Hooks

  // Setup
  const [roleName, roleLevel] = (roleType ?? '').split(' - ');

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className={classNames('flex flex-col gap-0', className)}>
      {roleType && companyName ? (
        <>
          <span className="text-sm font-medium text-gray-1">{companyName}</span>
          <div className="flex flex-row items-center gap-1">
            <span className="text-xs text-gray-1">{roleName}</span>
            <span className="h-1 w-1 rounded-full bg-gray-7" />
            <span className="text-xs text-gray-3">{roleLevel}</span>
          </div>
        </>
      ) : (
        <span className="text-sm font-medium text-gray-1">{title}</span>
      )}
    </div>
  );
};
